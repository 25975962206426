import { A, Navigate, action, redirect, useSearchParams } from "@solidjs/router";
import { createSignal, onMount } from "solid-js";
import { customerStore, StoredCustomer } from "../lib/user-store";
const [storedCustomer, setStoredCustomer] = customerStore;
import { track, identifyByUniqueId } from "~/lib/customer-io";

export default function Survey() {
  const [hasResponded, setHasResponded] = createSignal(false);
  const [searchParams, setSearchParams] = useSearchParams();


  const submitCityQuestion = action(async (formData: FormData) => {
    const city = String(formData.get("city"));
    track("survey-answer", {
      question_slug: "city-living-in",
      city_living_in: city,
    });

    setHasResponded(true);
  }, "submit-city-question-action");

  onMount(() => {
    const customerIdProvided = searchParams.id;

    if (!customerIdProvided) {
      console.error("No customer id provided in query params");
      return;
    }

    identifyByUniqueId(customerIdProvided);
  });
  return (
    <main class="text-center mx-auto text-gray-700 p-4">
      <div class="grid grid-cols-1 pt-10 pr-8 pl-8   ">
        {hasResponded() === false && (
          <form
            id="city-form"
            class=""
            action={submitCityQuestion}
            method="post"
          >
            <div class={`mb-0 text-left `}>
              <div class="pt-8">
                <div class="max-w-md pb-3  text-left">
                  <div class="pb-2">
                    <label class="text-3xl  font-serif " for="city">
                      🌆 Hey, there, what <span class="fff-th">city</span> do you{" "}
                      <span class="fff-th">live</span> in?
                    </label>
                  </div>
                  <input
                    type="text"
                    name="city"
                    autocomplete="address-level2"
                    required
                    class="shadow appearance-none border rounded w-60 my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Lagos, Nigeria"
                  ></input>
                </div>

                <button
                  id="submit-subscribe-form-button"
                  class="fff-button disabled:opacity-75; mr-4"
                  type="submit"
                  style="height:3rem"
                >
                  {"♥ Submit"}
                </button>

              </div>
            </div>
          </form>
        )}


        {hasResponded() && (
          <div class={`px-8 mb-4 max-w-screen-md`}>
            <p class="text-4xl text-white font-serif my-6 pt-8 pr-2 ">
              You are a star!
            </p>
            <p class="pb-4">
              That is all, you are dismissed and can go out for a walk in the
              sun!
            </p>
          </div>
        )}
      </div>
    </main>
  );
}
